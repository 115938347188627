const handleFileUpload = (event, callback) => {
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const moods = JSON.parse(e.target.result);
            callback(moods);
            saveMood(moods); // Save to local storage
        };
        reader.readAsText(file);
    }
};


const downloadMoods = (moods) => {
    const fileName = "moods.json";
    const json = JSON.stringify(moods);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const saveMood = (moods) => {
    localStorage.setItem('moods', JSON.stringify(moods));
};

const loadMood = () => {
    const savedMoods = localStorage.getItem('moods');
    return savedMoods ? JSON.parse(savedMoods) : {};
};

export {
    handleFileUpload,
    downloadMoods,
    saveMood,
    loadMood
};