import React from 'react';
import classnames from "classnames";
import {MoodOptions} from "./enum";

const DayCell = ({ date, mood, setMood }) => {

    const clearMood = (event) => {
        event.preventDefault();
        setMood(date, undefined);
    }

    const cycleMood = () => {
        const moodOptionValues = Object.values(MoodOptions);
        moodOptionValues.push({ value: "", displayName: "" }); // empty to reset the mood for that day

        let currentMood = mood || ''
        let currentMoodIndex = moodOptionValues.findIndex(option => option.value === currentMood);
        let nextMoodIndex = currentMoodIndex + 1;
        if (nextMoodIndex === moodOptionValues.length) {
            nextMoodIndex = 0;
        }
        let nextMood = moodOptionValues[nextMoodIndex].value;

        setMood(date, nextMood);
    }

    const classNames = {};
    classNames[`mood-${mood}`] = mood !== undefined;

    return (
        <td className={classnames(classNames)} onClick={cycleMood} onContextMenu={clearMood}></td>
    );
};

export default DayCell;
