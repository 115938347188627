const EditMode = {
    CURRENT_DAY: 'current-day',
    ALL: 'all'
}

const ViewMode = {
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
    YEARLY: 'yearly'
}

const MoodOptions = {
    AWESOME: { value: 'awesome', displayName: 'Awesome' },
    GOOD: { value: 'good', displayName: 'Good' },
    MEH: { value: 'meh', displayName: 'Meh' },
    BAD: { value: 'bad', displayName: 'Bad' },
    TERRIBLE: { value: 'terrible', displayName: 'Terrible' }
};


export {
    EditMode,
    ViewMode,
    MoodOptions
}