import React from 'react';
import './App.css';
import Calendar from './Calendar';

function App() {
  return (
      <div className="App">
        <Calendar />
      </div>
  );
}

export default App;