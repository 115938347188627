import React, { useState } from 'react';
import DayCell from './DayCell';
import {downloadMoods, handleFileUpload, loadMood, saveMood} from "./api";
import {EditMode, MoodOptions, ViewMode} from "./enum";
import {getDayName, getDaysInMonth, getDefaultViewMode, getMonthName, isEditable} from "./utils";

const Calendar = () => {
    const [moods, setMoods] = useState(loadMood());
    const [editMode, setEditMode] = useState(EditMode.CURRENT_DAY);

    const [viewMode, setViewMode] = useState(getDefaultViewMode());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

    const fileInputRef = React.useRef(null);

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const setMood = (date, mood) => {
        const [month, day] = date.split('-');
        if (!isEditable(month, day, editMode)) {
            return;
        }

        const newValue = { ...moods, [date]: mood };

        setMoods(newValue);
        saveMood(newValue);
    };

    return (
        <div>

        <button onClick={() => setViewMode(ViewMode.WEEKLY)}>Weekly View</button>
        <button onClick={() => setViewMode(ViewMode.MONTHLY)}>Monthly View</button>
        <button onClick={() => setViewMode(ViewMode.YEARLY)}>Yearly View</button>

        {viewMode === ViewMode.MONTHLY && (
            <select onChange={(e) => setSelectedMonth(parseInt(e.target.value))} value={selectedMonth}>
                {[...Array(12).keys()].map((month, index) => (
                    <option key={index} value={month}>{getMonthName(month + 1)}</option>
                ))}
            </select>
        )}

        <table>
            {viewMode === ViewMode.YEARLY && renderYearly(moods, setMood)}
            {viewMode === ViewMode.MONTHLY && renderMonthly(moods, setMood, selectedMonth)}
            {viewMode === ViewMode.WEEKLY && renderWeekly(moods, setMood)}
            <tfoot>
            <tr>
                <td colSpan="32">Mood Legend:</td>
            </tr>
            <tr>
                {Object.values(MoodOptions).map(option => (
                    <td key={option.value} className={`mood-${option.value}`}>
                        <span>{option.displayName}</span>
                    </td>
                ))}
            </tr>
            </tfoot>
        </table>
        <button onClick={() => {downloadMoods(moods)}}>Download calendar</button>

        <input
            type="file"
            onChange={(event => {
                handleFileUpload(event, setMoods);
            })}
            id="fileUpload"
            style={{ display: 'none' }}
            ref={fileInputRef}
        />
        <button onClick={handleUploadClick}>Upload calendar</button>
        <button onClick={() => {
            setEditMode(editMode === EditMode.CURRENT_DAY ? EditMode.ALL : EditMode.CURRENT_DAY);
        }}>Edit mode: {editMode === EditMode.ALL ? 'all' : 'current day'}</button>
        </div>
    );
};

const renderMonthly = (moods, setMood, selectedMonthIndex) => {
    const month = selectedMonthIndex + 1;

    return (
        <>
            <thead>
            <tr>
                <th></th>
                {[...Array(getDaysInMonth(month)).keys()].map(day => <th key={day}>{day + 1}</th>)}
            </tr>
            </thead>

            <tbody>
                <tr key={month}>
                    <td>{getMonthName(month)}</td>
                    {[...Array(getDaysInMonth(month)).keys()].map(day => (
                        <DayCell
                            key={`${month}-${day + 1}`}
                            date={`${month}-${day + 1}`}
                            mood={moods[`${month}-${day + 1}`]}
                            setMood={setMood}
                        />
                    ))}
                </tr>
            </tbody>
        </>
    )
}

const renderYearly = (moods, setMood) => {
    return (
        <>
            <thead>
            <tr>
                <th></th>
                {[...Array(31).keys()].map(day => <th key={day}>{day + 1}</th>)}
            </tr>
            </thead>
            <tbody>
            {[...Array(12).keys()].map(monthIndex => {
                const month = monthIndex + 1;
                return (
                    <tr key={month}>
                        <td>{getMonthName(month)}</td>
                        {[...Array(getDaysInMonth(month)).keys()].map(day => (
                            <DayCell
                                key={`${month}-${day + 1}`}
                                date={`${month}-${day + 1}`}
                                mood={moods[`${month}-${day + 1}`]}
                                setMood={setMood}
                            />
                        ))}
                    </tr>
                );
            })}
            </tbody>
        </>
    )
}

const renderWeekly = (moods, setMood) => {

    function getCurrentWeekKeys() {
        const today = new Date();
        const currentDayOfWeek = today.getDay();
        const daysUntilFirstDayOfWeek = (currentDayOfWeek + 6) % 7;

        // Start of the week (Monday)
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - daysUntilFirstDayOfWeek);

        const weekKeys = [];

        for (let i = 0; i < 7; i++) {
            const dayDate = new Date(startOfWeek);
            dayDate.setDate(startOfWeek.getDate() + i);

            const month = dayDate.getMonth() + 1; // JavaScript months are 0-indexed
            const day = dayDate.getDate();

            weekKeys.push(`${month}-${day}`);
        }

        return weekKeys;
    }

    const getCurrentWeekData = (data, firstDayOfWeek = 0) => {

        const weekKeys = getCurrentWeekKeys();
        const weekData = {};

        weekKeys.forEach((key, index) => {
            weekData[key] = data[key] ?? "";
        });

        return weekData;
    };

    const currentWeekData = getCurrentWeekData(moods);

    return (
        <>
            <tbody className="weekly-view">

                {Object.entries(currentWeekData).map(([date, mood]) => {
                    const [month, day] = date.split('-');
                    return (
                        <tr key={date}>
                            <td className="date">
                                <div>
                                    {getDayName(month, day)} <span>({day})</span>
                                </div>
                            </td>
                            <DayCell
                                key={date}
                                date={date}
                                mood={mood}
                                setMood={setMood}
                            />
                        </tr>
                    );
                })}

            </tbody>
        </>
    );
}

export default Calendar;
