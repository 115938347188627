import {EditMode, ViewMode} from "./enum";

const isEditable = (month, day, editMode) => {
    if (editMode === EditMode.ALL) {
        return true;
    }

    const date = new Date();
    date.setMonth(month - 1);
    date.setDate(day);

    return !!isToday(date);
};

const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

const getDaysInMonth = (month) => {
    const year = new Date().getFullYear();

    switch (month) {
        case 2: return isLeapYear(year) ? 29 : 28; // February
        case 4: // April
        case 6: // June
        case 9: // September
        case 11: // November
            return 30;
        default: return 31;
    }
};

const getMonthName = (month) => {
    const date = new Date();
    date.setMonth(month - 1);
    return date.toLocaleString('default', { month: 'long' });
};

const getDayName = (month, day) => {
    const date = new Date();
    date.setDate(day);
    return date.toLocaleString('default', { weekday: 'long' });
}

const isToday = (currentDate) => {
    const today = new Date();
    return currentDate.getDate() === today.getDate() &&
        currentDate.getMonth() === today.getMonth() &&
        currentDate.getFullYear() === today.getFullYear();
};

const isMobileBrowser = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for mobile device patterns in the user agent string
    return /android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|plucker|pocket|psp|symbian|treo|up\.browser|up\.link|webos|windows phone/i.test(userAgent) ||
        // Check for iPadOS specifically (which in newer versions doesn't include "mobile" in the user agent)
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
}

const getDefaultViewMode = () => {
    if (isMobileBrowser()) {
        return ViewMode.WEEKLY;
    }

    return ViewMode.MONTHLY;
}

export {
    isEditable,
    isLeapYear,
    getDaysInMonth,
    getMonthName,
    getDayName,
    getDefaultViewMode
}